.#{$prefix}-form{
  &-line{
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }

    & > * { 
      width: 100%;
    }
  }
}


hr.separator {
  height: 1px;
  background-color: #BBBBBB;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 0;
}